import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserTokenDto } from '../models/SessionInfoDto';
import { JwtRefreshService } from '../services/jwt-refresh.service';

@Injectable()
export class BasicAuthHttpInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  publicRequests = ['auth/authenticate', 'auth/refresh', 'onboard-reset-password'];
  serverUrl: string = environment.baseUrl;

  constructor(private jwtRefreshService: JwtRefreshService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isPublicRequest = this.publicRequests.includes(request.url);

    const baseUrl = request.url.startsWith('assets') || request.url.startsWith('/assets')
      ? request.url
      : `${this.serverUrl.replace(/\/$/, '')}/${request.url.replace(/^\//, '')}`;

    const localUserTokenDto = localStorage.getItem('userTokenDto');
    const userTokenDto = localUserTokenDto ? JSON.parse(localUserTokenDto) as UserTokenDto : undefined;

    let modifiedRequest = request.clone({
      url: baseUrl,
      headers: request.headers
        .set("Cache-Control", 'no-cache')
        .set("Pragma", 'no-cache')
        .set("Expires", '0')
    });

    if (!isPublicRequest && userTokenDto) {
      modifiedRequest = modifiedRequest.clone({
        headers: modifiedRequest.headers.set('Authorization', "Bearer " + userTokenDto.jwtToken)
      });
    }

    return next.handle(modifiedRequest).pipe(
      catchError(error => {
        if (error.status === 403 && !isPublicRequest) {
          return this.handle403Error(modifiedRequest, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.jwtRefreshService.refreshToken().pipe(
        switchMap(newToken => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newToken.jwtToken);

          const newRequest = request.clone({
            headers: request.headers.set('Authorization', "Bearer " + newToken.jwtToken)
          });

          return next.handle(newRequest);
        }),
        catchError(err => {
          this.isRefreshing = false;
          localStorage.clear();
          return throwError(() => err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          const newRequest = request.clone({
            headers: request.headers.set('Authorization', "Bearer " + token)
          });
          return next.handle(newRequest);
        })
      );
    }
  }
}

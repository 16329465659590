import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { OrganizationWrapperUiDto } from '../../models/OrganizationWrapperUiDto';
import { UserUiDto } from '../../models/UserUiDto';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  organizationUiDto?: OrganizationWrapperUiDto;
  userUiDto?: UserUiDto;

  menuItems = [
    { title: 'Edit', escape: false },
  ]

  constructor(
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
  }
}

export enum DscNotificationEnum {
  CREATE = "CREATE",
  DSC_ADDED = "DSC_ADDED",
  DSC_CONNECTION_DISCONNECT = "DSC_CONNECTION_DISCONNECT",
  DSC_DELETED = "DSC_DELETED",
  DSC_CONNECTION_REQUESTED = "DSC_CONNECTION_REQUESTED",
  DSC_CONNECTION_CONNECTED = "DSC_CONNECTION_CONNECTED",
  DSC_CONNECTION_DISCONNECTED = "DSC_CONNECTION_DISCONNECTED",
  DSC_ADD="DSC_ADD",
  DSC_VALIDATED='DSC_VALIDATED',
  INCORRECT_PIN='INCORRECT_PIN'
}
import { createFeature, createReducer, on } from "@ngrx/store";
import { TechnicalDetailsWrapperUiDto } from "../../models/questionnaire/TechnicalDetailsWrapperUiDto";
import { QuestionnaireActions } from "./questionnaire.actions";

interface State {
  technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto | null
}

const initialState: State = {
  technicalDetailsWrapperUiDto: null
};


export const questionnaireFeature = createFeature({
  name: 'QuestionnaireFeature',
  reducer: createReducer(
    initialState,

    on(QuestionnaireActions.setQuestionnaireDetailsUiDto, (state, { technicalDetailsWrapperUiDto }) => ({
      ...state,
      technicalDetailsWrapperUiDto
    })),
    on(QuestionnaireActions.getQuestionnaireDetailsUiDto, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearQuestionnaireDetailsUiDto, (state) => ({
      ...state,
      tenderUiDto: null,
    })),
  ),
});

export const {
  name,
  reducer,
  selectTechnicalDetailsWrapperUiDto
} = questionnaireFeature
import { createActionGroup, props, emptyProps } from "@ngrx/store";
import { TechnicalDetailsWrapperUiDto } from "../../models/questionnaire/TechnicalDetailsWrapperUiDto";

export const QuestionnaireActions = createActionGroup({
  source: 'Questionnaire',
  events: {
    'Set Questionnaire Details Ui Dto': props<{ technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto }>(),
    'Get Questionnaire Details Ui Dto': emptyProps(),
    'Clear Questionnaire Details Ui Dto': emptyProps(),
  }
})
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"
import { ApplicationUtils } from "../utils/ApplicationUtils";

export function ColorValidators(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const validColor =  ApplicationUtils.isValidColor(control.value);
      return !validColor ? { invalidColor: { value: control.value } } : null;
    } else {
      return null;
    }
  };

}

export function differentUrlsValidator(controlNameToCompare: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlToCompare = control.root.get(controlNameToCompare);

    if (controlToCompare && control.value && control.value === controlToCompare.value) {
      return { sameUrl: true };
    }
    return null;
  };
}
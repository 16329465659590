import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { EncryptionService } from '../services/encryption.service';

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {

  constructor(private encryptionService: EncryptionService ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedRequest = req;
    let auctionSessionId = localStorage.getItem('AUC_SESSION_ID');
 
    // Check if the X-SECURE-PAYLOAD header is set to 'true'
    if (req.headers.has('X-SECURE-PAYLOAD')) {
      // Encrypt the body of the request if it's not null
      if (clonedRequest.body) {
        let key  = auctionSessionId?.substring(0, 32)
        const encryptedBody = this.encryptionService.encryptData(clonedRequest.body, key!)//this.encrypt(JSON.stringify(clonedRequest.body));
        clonedRequest = clonedRequest.clone({
          body: encryptedBody
        });
        console.log('Encrypted Request:', clonedRequest);
      }
    }

    // Handle the response
    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body) {
          // If the X-SECURE-PAYLOAD header is set, decrypt the response body
          if (event.headers.has('X-SECURE-PAYLOAD')) {
            let key  = auctionSessionId?.substring(0, 32)
            const decryptedBody = this.encryptionService.decryptData(clonedRequest.body, key!)//this.decrypt(event.body);
            console.log('Decrypted Response:', decryptedBody);
            return event.clone({ body: JSON.parse(decryptedBody) });
          }
        }
        return event;
      })
    );
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { UserTokenDto, SessionInfoDto } from '../models/SessionInfoDto';
import { SessionActions } from '../state-management/session/session.actions';
import { ApplicationConstants } from '../utils/ApplicationConstants';

@Injectable({
  providedIn: 'root'
})
export class JwtRefreshService {

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) { }


  refreshToken(): Observable<any> {
    const localUserTokenDto = localStorage.getItem('userTokenDto');
    const userTokenDto = localUserTokenDto ? JSON.parse(localUserTokenDto) as UserTokenDto : undefined;
  
    const params = new HttpParams().set('refreshToken', userTokenDto?.refreshToken || '');
  
    return this.httpClient.post<any>('auth/refresh', null, { params, observe: 'response' }).pipe(
      map(apiResponseDto => {
        if (apiResponseDto.status == 200) {
          const userTokenDto = apiResponseDto.body.jwtData as UserTokenDto;
          localStorage.setItem('userTokenDto', JSON.stringify(userTokenDto));
  
          return userTokenDto;  // Return the updated token
        } else {
          throw new Error("Token refresh failed");
        }
      }),
      catchError((error) => {
        console.error("Token refresh error:", error);
        return throwError(() => new Error(error?.error?.message || "Token refresh failed"));
      })
    );
  }

}

import { createFeature, createReducer, on } from "@ngrx/store";
import { PQActions } from "./pq.actions";
import { TenderSourcingEventWrapperUiDto } from "../../models/tenders/TenderSourcingEventWrapperUiDto";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";
import { PqUiDto } from "../../models/pcpq/PqUiDto";
import { PqEvaluationWrapperDto } from "../../models/pcpq/PqEvaluationWrapperDto";

interface State {
  pqSourcingEventWrapperDto: TenderSourcingEventWrapperUiDto | null;
  sourcingEventUiDto: SourcingEventUiDto | null;
  pqUiDto: PqUiDto | null;
  pqEvaluationWrapperDto: PqEvaluationWrapperDto | null
}

const initialState: State = {
  pqSourcingEventWrapperDto: null,
  sourcingEventUiDto: null,
  pqUiDto: null,
  pqEvaluationWrapperDto: null,
};

export const pqFeature = createFeature({
  name: 'PQFeature',
  reducer: createReducer(
    initialState,
    //SourcingEvent-----------------
    on(PQActions.setCurrentSourcingEventUiDto, (state, { sourcingEventUiDto }) => {
      localStorage.setItem('currentEvent', JSON.stringify(sourcingEventUiDto));
      return {
        ...state,
        sourcingEventUiDto
      }
    }),
    on(PQActions.getSourcingEventUiDto, (state) => ({
      ...state,
    })),
    //-----------------------

    // Current Pq Wrapper Ui Dto
    on(PQActions.setCurrentPqUiDto, (state, { pqUiDto }) => ({
      ...state,
      pqUiDto
    })),

    on(PQActions.getCurrentPqUiDto, (state) => ({
      ...state,
    })),
    on(PQActions.clearCurrentPqUiDto, (state) => ({
      ...state,
      pqUiDto: null,
    })),


    on(PQActions.getCurrentPqOpportunityWrapperDto, (state) => ({
      ...state
    })),
    on(PQActions.clearCurrentPqOpportunityWrapperDto, (state) => ({
      ...state,
      pqOpportunityWrapperDto: null
    })),


    on(PQActions.getAllAdminUsers, (state) => ({
      ...state
    })),

    on(PQActions.setPqDraftStatus, (state, { draftSubStatus: draftSubStatusStatus }) => ({
      ...state,
      sourcingEventUiDto: {
        ...state.sourcingEventUiDto,
        stages: {
          ...state.sourcingEventUiDto?.stages, // Preserve existing stages
          [draftSubStatusStatus]: true // Dynamically set the status to true
        }
      }
    })),

    on(PQActions.setPqEvaluationWrapperDto, (state, { pqEvaluationWrapperDto }) => ({
      ...state,
      pqEvaluationWrapperDto
    })),
    on(PQActions.getPqEvaluationWrapperDto, (state) => ({
      ...state,
    })),
    on(PQActions.clearPqEvaluationWrapperDto, (state) => ({
      ...state,
      pqEvaluationWrapperDto: null,
    })),

    on(PQActions.setBidderEvaluationData, (state, { bidderEvaluationDataDto }) => {
      // Find the index of the bidder summary data to update
      const updatedBidderEvaluationSummaryDataDtoList = state.pqEvaluationWrapperDto?.pqEvaluationSummaryDataDtoList?.map((summary) => {
        if (summary.bidderUserId === bidderEvaluationDataDto.bidderUserId) {
          // Update the evaluationStatus field
          return {
            ...summary,
            evaluationStatus: bidderEvaluationDataDto.evaluationStatus,
          };
        }
        return summary; // Keep other summaries unchanged
      });
      return {
        ...state,
        bidderEvaluationDataDto, // Update bidderEvaluationDataDto
        pqEvaluationWrapperDto: {
          ...state.pqEvaluationWrapperDto,
          bidderEvaluationSummaryDataDtoList: updatedBidderEvaluationSummaryDataDtoList || state.pqEvaluationWrapperDto?.pqEvaluationSummaryDataDtoList,
        },
      };
    }),

  ),
});

export const {
  name,
  reducer,
  //   selectPqManagementFeatureState,
  selectPqSourcingEventWrapperDto,
  selectPqUiDto,
  selectSourcingEventUiDto,
  selectPqEvaluationWrapperDto,
} = pqFeature
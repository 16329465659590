import { Injectable } from '@angular/core';
import { TenderInitialUiDto } from '../../models/tenders/TenderInitialUiDto';

import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from '../error.service';
import { ThemeService } from '../theme.service';
import { BehaviorSubject, catchError, firstValueFrom, Observable, tap } from 'rxjs';

import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { SourcingEventUiDto } from '../../models/tenders/SourcingEventUiDto';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { TenderSourcingEventWrapperUiDto } from '../../models/tenders/TenderSourcingEventWrapperUiDto';
import { TenderRequestDto } from '../../models/tenders/TenderRequestDto';
import { TenderReferenceUiDto } from '../../models/tenders/TenderReferenceDto';
import { TenderAddressUiDto } from '../../models/tenders/TenderAddressUiDto';
import { TenderAuctionAndPqStatsUiDto } from '../../models/tenders/TenderAuctionAndPqStatsUiDto ';


@Injectable({
  providedIn: 'root'
})
export class TenderInitialService {
  private _tenderDataDTO$ = new BehaviorSubject<TenderSourcingEventWrapperUiDto>({});
  private tenderDataDTO: TenderSourcingEventWrapperUiDto = {};
  private resetTenderDataDTO: TenderSourcingEventWrapperUiDto = {};
  private _tenderRefrenceNoList$ = new BehaviorSubject<TenderReferenceUiDto[]>([]);
  private tenderRefrenceNoList: TenderReferenceUiDto[] = [];

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private themeService: ThemeService,
    private store: Store
  ) { }

  get getTenderData$() { return this._tenderDataDTO$.asObservable(); }
  get getTenderRefrenceNoList$() { return this._tenderRefrenceNoList$.asObservable(); }

  //Get Tender list----------------------------
  async loadSourcingEventUiDto(tenderRequestDto: TenderRequestDto) {
    try {
      let apiResponseDto = await firstValueFrom(this.getAllSourcingEventList(tenderRequestDto));
      console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setTenderSourcingEventWrapperUiDto(apiResponseDto.data as TenderSourcingEventWrapperUiDto);
      } else {
        this.setTenderSourcingEventWrapperUiDto({} as TenderSourcingEventWrapperUiDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadAllListCount() {
    try {
      let apiResponse = await firstValueFrom(this.getAllListCount());
      console.log("apiResponse 58",apiResponse);
      
      if (apiResponse && apiResponse.code == ApplicationConstants.SUCCESS_CODE) {
      let apiResponseDto = apiResponse.data as TenderAuctionAndPqStatsUiDto;
      console.log("apiResponseDto",apiResponseDto);
      }else{
        console.error("Failed to fetch valid data",apiResponse)
      }
    } catch (error) {
      console.error(error);
    }
  }

  getAllSourcingEventList(tenderRequestDto: TenderRequestDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/tenders/tenders`, tenderRequestDto).pipe(
      tap(_ => console.log("Fetch tender list")),
      catchError(this.errorService.handleError<any>("Error while fetching tender list")));
  }
  //---------------------------------------------------

  
  //Get refrence number list-----------------------------
  async loadReferenceList() {
    try {
      let apiResponse = await firstValueFrom(this.GetReferenceNoList());
      if (apiResponse && apiResponse.code == ApplicationConstants.SUCCESS_CODE) {
        this.setReferenceList(apiResponse.data as TenderReferenceUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  GetReferenceNoList(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`/tenders/referenceNoList`).pipe(
      tap(_ => console.log("Save tender")),
      catchError(this.errorService.handleError<any>("Error while saving tender")));
  }

  setReferenceList(tenderReferenceList: TenderReferenceUiDto[]) {
    this.tenderRefrenceNoList = tenderReferenceList;
    this._tenderRefrenceNoList$.next(this.tenderRefrenceNoList);
  }
  //-------------------------------------------------

  setTenderSourcingEventWrapperUiDto(tenderData: TenderSourcingEventWrapperUiDto) {
    this.tenderDataDTO = tenderData;
    this._tenderDataDTO$.next(this.tenderDataDTO);
  }

  //Save Tender
  tenderInitiate(tenderInitialUiDto: TenderInitialUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/tenders/tender-initial`, tenderInitialUiDto).pipe(
      tap(_ => console.log("Save tender")),
      catchError(this.errorService.handleError<any>("Error while saving tender")));
  }
  saveTenderAddress(tenderAddressUiDto: TenderAddressUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/tenders/address`, tenderAddressUiDto).pipe(
      tap(_ => console.log("Save tender address")),
      catchError(this.errorService.handleError<any>("Error while saving tender address"))
    );
  }

  getAllListCount(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`/bidder-common/stats`).pipe(
      tap(_ =>console.log("fetched list count")),
      catchError(this.errorService.handleError<any>("Error while fetching list count")));
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { SessionActions } from '../state-management/session/session.actions';
import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { selectUserUiDto } from '../state-management/session/session.features';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { UserUiDto } from '../models/UserUiDto';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { UserService } from './user.service';
import { AdminService } from './admin.service';
import { ApplicationUtils } from '../utils/ApplicationUtils';
import { OnboardingService } from './onboarding.service';
import { MasterDataService } from '../master-data.service';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private errorService: ErrorService,
    private messageService: MessageService,
    private userService: UserService,
    private adminService: AdminService,
    private socketService: SocketService,
    private onboardingService: OnboardingService,
    private masterDataService: MasterDataService,
    private store: Store
  ) { }

  async logout() {
    let userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
    await firstValueFrom(this.userService.logout(userUiDto?.primaryEmailId));

    localStorage.clear();
    this.errorService.setResetDialogFlag();
    // this.userService.clear();
    // this.dataRedirection.clearData();
    // this.bidderService.clearData();
    // appLoader?.closeLoaderForcefully();

    this.store.dispatch(SessionActions.clearSession())
    this.router.navigate(['/'], {skipLocationChange: true});
  }

  public async initializeListenerAndDoRouting(sessionInfo: SessionInfoDto) {
    let userUiDto: UserUiDto = sessionInfo.userUiDto!;

    const deploymentConfig = await firstValueFrom(this.adminService.getDeploymentConfiguration$);
    console.log("deploymentConfig",deploymentConfig);
    
    if (deploymentConfig?.nodeServerUrl) {
      this.socketService.connect(deploymentConfig?.nodeServerUrl);
    }
    // await this.applicationStartupCacheService.loadApplicationStartupDto();

    if (!ApplicationUtils.isSuperAdmin(userUiDto)) {
    //   this.applicationListenerService.loadAndListenBidderRegistrationDataOfAuctionHouse();
    //   await this.applicationListenerService.loadApplicationDataAfterLogin();
      await this.onboardingService.loadOrganizationUiDto();
      await this.masterDataService.initMasterData();
    }

    if (userUiDto.forcePasswordChange) {
      this.router.navigate(["/changePassword"], { skipLocationChange: true });
    } else {
      if (userUiDto.registrationCompleted) {
        if (ApplicationUtils.isSuperAdmin(userUiDto)) {
          this.router.navigate(["/SuperAdmin"], { skipLocationChange: true });
        } else {
          if (sessionInfo.dscVerified) {
            this.router.navigate(["/Admin/"], { skipLocationChange: true });
          } else {
            this.router.navigate(["/verifyDsc"], { skipLocationChange: true });
          }
        }
      } else {
        this.router.navigate(["/userOnboarding"], { skipLocationChange: true });
      }
    }
  }

  handleServerResponse(data: any) {
    let sessionInfo = data.sessionInfoDto as SessionInfoDto;

    // this.navigationService.emitChange(currentUser);
    this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
    this.initializeListenerAndDoRouting(sessionInfo);
  }

  authenticate(username?: string, password?: string, otpId?: string): Observable<any> {
    if (!otpId) {
      otpId = "";
    }

    let headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + password + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response', withCredentials: true}).pipe(
      switchMap(apiResponseDto => {
        if (apiResponseDto.status === 200) {
          localStorage.setItem('AUC_SESSION_ID', apiResponseDto.body);

          return this.httpClient.get<any>('/userDetails', { headers, observe: 'response', withCredentials: true });
        }
        // If the first API response is not successful, throw an error or return an observable that does nothing
        return of(apiResponseDto);
      }),
      // Handle the userDetails API response
      map(userDetailsResponse => {
        if (userDetailsResponse.body.code === ApplicationConstants.SUCCESS_CODE) {
          let sessionInfo = <SessionInfoDto>userDetailsResponse.body.data.sessionInfoDto;
          this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
        }
        return userDetailsResponse.body;
      })
    )
  }

  doAuthentication(userName: string, password: string, otpId?: string) {
    this.authenticate(userName, password, otpId).subscribe({
      next: (apiResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.handleServerResponse(apiResponseDto.data);
        } else {
          localStorage.clear();
          // appLoader.closeLoaderForcefully();
          this.store.dispatch(SessionActions.clearSession());

          if (apiResponseDto.code == "USER-107") {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Email Id Not Registered" });
          } else if (apiResponseDto.code == "USER-142") {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Account Disabled" });
          }
          else if (apiResponseDto.code == "USER-144") {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "You are not authorized to login" });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
          }

        }
      },
      error: (error) => {
        // appLoader.closeLoaderForcefully();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Wrong Credentials" });
        localStorage.clear();
      }
    });
  }

  authenticateWithOtp(username: string, userOtp: string, otpId: string): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + userOtp + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('WORKFLOW', 'LOGIN_WITH_OTP');

    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response' }).pipe(
      map(
        apiResponseDto => {
          if (apiResponseDto.body.code == ApplicationConstants.SUCCESS_CODE) {
            // session info only when authentication is successful.
            let sessionInfo = <SessionInfoDto>apiResponseDto.body.data.sessionInfoDto;
            this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
            localStorage.setItem('AUC_SESSION_ID', apiResponseDto.body.data.sessionID);
          }
          return apiResponseDto.body;
        }
      )
    )
  }


}

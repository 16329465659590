import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";
import { DraftSubStatus } from "../../enums/TenderDraftSubStatus";
import { PqUiDto } from "../../models/pcpq/PqUiDto";
import { BidderEvaluationDataDto } from "../../models/questionnaire/BidderEvaluationDataDto";
import { PqEvaluationWrapperDto } from "../../models/pcpq/PqEvaluationWrapperDto";

export const PQActions = createActionGroup({
  source: 'PQ',
  events: {
    'Set Current Sourcing Event Ui Dto': props<{ sourcingEventUiDto: SourcingEventUiDto }>(),
    'Get Sourcing Event Ui Dto': emptyProps(),

    'Set Current Pq Ui Dto': props<{ pqUiDto: PqUiDto }>(),
    'Get Current Pq Ui Dto': emptyProps(),
    'Clear Current Pq Ui Dto': emptyProps(),

    'Get Current Pq Opportunity Wrapper Dto': emptyProps(),
    'Clear Current Pq Opportunity Wrapper Dto': emptyProps(),

    'Get All Admin Users': emptyProps(),

    'Set Bidder Evaluation Data': props<{ bidderEvaluationDataDto: BidderEvaluationDataDto }>(),
    'Get Bidder Evaluation Data': emptyProps(),
    'Clear Bidder Evaluation Data': emptyProps(),

    'Set Pq Evaluation Wrapper Dto': props<{ pqEvaluationWrapperDto: PqEvaluationWrapperDto }>(),
    'Get Pq Evaluation Wrapper Dto': emptyProps(),
    'Clear Pq Evaluation Wrapper Dto': emptyProps(),

    'Set Pq Draft Status': props<{ draftSubStatus: DraftSubStatus }>(),
  }
})
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LucideAngularModule, icons } from 'lucide-angular';
import { ConfirmationService, MessageService } from 'primeng/api';
import { navigationFeature } from './shared/state-management/navigation/navigation.features';
import { sessionFeature } from './shared/state-management/session/session.features';
import { DialogService } from 'primeng/dynamicdialog';
import { BasicAuthHttpInterceptor } from './shared/interceptors/basic-auth-http.interceptor';
import { SharedModule } from './shared/shared.module';
import { onboardingFeature } from './shared/state-management/onboarding/onboarding.features';
import { NgxEchartsModule } from 'ngx-echarts';
import { tenderManagementFeature } from './shared/state-management/tender/tender.features';
import { questionnaireFeature } from './shared/state-management/questionnaire/questionnaire.features';
import { ApBidderListComponent } from './layouts/Auction/Auction-publish/ap-bidder-list/ap-bidder-list.component';
import { ApAuctionAddressComponent } from './layouts/Auction/Auction-publish/ap-auction-address/ap-auction-address.component';
import { ApAuctionCriticalDatesComponent } from './layouts/Auction/Auction-publish/ap-auction-critical-dates/ap-auction-critical-dates.component';
import { ApAuctionDocumentsComponent } from './layouts/Auction/Auction-publish/ap-auction-documents/ap-auction-documents.component';
import { ApAuctionApprovalComponent } from './layouts/Auction/Auction-publish/ap-auction-approval/ap-auction-approval.component';
import { ApAuctionDetailComponent } from './layouts/Auction/Auction-publish/ap-auction-detail/ap-auction-detail.component';
import { ApCreatorStageComponent } from './layouts/Auction/Auction-publish/ap-creator-stage/ap-creator-stage.component';
import { ApHeaderStageComponent } from './layouts/Auction/Auction-publish/ap-header-stage/ap-header-stage.component';
import { ApHeaderStatusComponent } from './layouts/Auction/Auction-publish/ap-header-status/ap-header-status.component';
import { ApPublisherStageComponent } from './layouts/Auction/Auction-publish/ap-publisher-stage/ap-publisher-stage.component';
import { ApSideBarComponent } from './layouts/Auction/Auction-publish/ap-side-bar/ap-side-bar.component';
import { ApAuctionSummaryComponent } from './layouts/Auction/Auction-publish/ap-auction-summary/ap-auction-summary.component';
import { ApAuctionLotComponent } from './layouts/Auction/Auction-publish/ap-auction-lot/ap-auction-lot.component';
import { AcAuctionLotComponent } from './layouts/Auction/Auction-create/ac-auction-lot/ac-auction-lot.component';
// import { AcAuctionLotAddItemsComponent } from './layouts/Auction/Auction-create/ac-auction-lot-add-items/ac-auction-lot-add-items.component';


@NgModule({
  declarations: [
    AppComponent,
   
  

    
  
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    LucideAngularModule.pick(icons),
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature(sessionFeature),
    StoreModule.forFeature(onboardingFeature),
    StoreModule.forFeature(navigationFeature),
    StoreModule.forFeature(tenderManagementFeature),
    StoreModule.forFeature(questionnaireFeature),
  ],
  providers: [
    MessageService, DialogService, ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

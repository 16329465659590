import { Injectable } from '@angular/core';
import { OrganizationWrapperUiDto } from '../models/OrganizationWrapperUiDto';
import { OnboardingActions } from '../state-management/onboarding/onboarding.actions';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from './error.service';
import { ThemeService } from './theme.service';
import { BehaviorSubject, catchError, firstValueFrom, Observable, tap } from 'rxjs';
import { selectUserUiDto } from '../state-management/session/session.features';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { OrgOrganogramUiDto } from '../models/OrgOrganogramUiDto';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { CompanyUiDto } from '../models/CompanyUiDto';
import { PlantUiDto } from '../models/PlantUiDto';
import { UserInfoUiDto } from '../models/UserInfoUiDto';
import { UserInviteDto } from '../models/UserInviteDto';
import { UserUiDto } from '../models/UserUiDto';
import { TeamUiDto } from '../models/TeamUiDto';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  private companyUiDtos: CompanyUiDto[] = [];
  private plantUiDtos: PlantUiDto[] = [];
  private _selectedPlantUiDto$ = new BehaviorSubject<PlantUiDto | undefined>(undefined);

  private _companyUiDtos$ = new BehaviorSubject<CompanyUiDto[]>([]);
  private _selectedCompanyUiDto$ = new BehaviorSubject<CompanyUiDto | undefined>(undefined);
  private _plantUiDtos$ = new BehaviorSubject<PlantUiDto[]>([]);
  private _internalUserInfoUiDtos$ = new BehaviorSubject<UserInfoUiDto[]>([]);
  private _internalUserInfoUiDtosBasedOnPlant$ = new BehaviorSubject<UserInfoUiDto[]>([]);

  private _teamUiDtos$ = new BehaviorSubject<TeamUiDto[]>([]);
  private _userUiDtos$ = new BehaviorSubject<UserUiDto[]>([]);
  private companyTabName: string = '';
  private plantTabName: string = '';

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private themeService: ThemeService,
    private store: Store
  ) { }

  get getCompanyUiDtos$() { return this._companyUiDtos$.asObservable(); }
  get getSelectedCompanyUiDto$() { return this._selectedCompanyUiDto$.asObservable(); };
  get getSelectedPlantUiDto$() { return this._selectedPlantUiDto$.asObservable(); };
  get getPlantUiDtos$() { return this._plantUiDtos$.asObservable(); }
  get getInternalUserinfoUiDtosBasedOnPlant$() { return this._internalUserInfoUiDtosBasedOnPlant$.asObservable(); }
  get getInternalUserinfoUiDtos$() { return this._internalUserInfoUiDtos$.asObservable(); }
  
  setCompanyTabName(tabName: string){
    this.companyTabName = tabName;
  }

  getCompanyTabName(){
    return this.companyTabName;
  }

  setPlantTabName(tabName: string){
    this.plantTabName = tabName;
  }

  getPlantTabName(){
    return this.plantTabName;
  }

  updateOrganizationUiDto(organizationWrapperUiDto: OrganizationWrapperUiDto) {
    this.store.dispatch(OnboardingActions.updateOrganizationUiDto({ organizationWrapperUiDto }));
    this.themeService.getThemeStructure(organizationWrapperUiDto);
  }

  updateOrgOrganogramUiDto(orgOrganogramUiDto: OrgOrganogramUiDto) {
    this.store.dispatch(OnboardingActions.saveOrgOrganogram({ orgOrganogramUiDto }));
    this.themeService.getThemeStructure(orgOrganogramUiDto.organizationWrapperUiDto!);
  }

  setCompanyUiDtos(companyUiDtos: CompanyUiDto[]) {
    this.companyUiDtos = companyUiDtos;
    this._companyUiDtos$.next(this.companyUiDtos);

    let selectedCompanyUiDto = this._selectedCompanyUiDto$.value;
    if (selectedCompanyUiDto) {
      selectedCompanyUiDto = this.companyUiDtos.find(item => item.companyCode == selectedCompanyUiDto?.companyCode);
      this._selectedCompanyUiDto$.next(selectedCompanyUiDto);
    }
  }

  updateCompanyUiDto(companyUiDto: CompanyUiDto) {
    let index = this.companyUiDtos.findIndex(item => item.companyCode == companyUiDto.companyCode);

    if (index != undefined && index > -1) {
      Object.assign(this.companyUiDtos[index], companyUiDto);
    } else {
      this.companyUiDtos.push(companyUiDto);
    }

    this._companyUiDtos$.next(this.companyUiDtos);
  }

  setCurrentCompanyUiDto(companyUiDto?: CompanyUiDto) {
    this._selectedCompanyUiDto$.next(companyUiDto);
  }


  updatePlantUiDto(plantUiDto: PlantUiDto) {
    let index = this.plantUiDtos.findIndex(item => item.plantCode == plantUiDto.plantCode && item.companyCode == plantUiDto.companyCode);

    if (index != undefined && index > -1) {
      Object.assign(this.plantUiDtos[index], plantUiDto);
    } else {
      this.plantUiDtos.push(plantUiDto);
      this._selectedPlantUiDto$.next(plantUiDto);
    }

    this._plantUiDtos$.next(this.plantUiDtos);

    // update selected plant 
    if(this._selectedPlantUiDto$.value){
      if(this._selectedPlantUiDto$.value.companyCode == plantUiDto.companyCode && 
        this._selectedPlantUiDto$.value.plantCode == plantUiDto.plantCode){
          this._selectedPlantUiDto$.next(plantUiDto);
        }
    }
  }

  setPlantUiDtos(plantUiDtos: PlantUiDto[]) {
    this.plantUiDtos = plantUiDtos;
    this._plantUiDtos$.next(this.plantUiDtos);
  }

  setCurrentPlantUiDto(plantUiDto?: PlantUiDto) {
    this._selectedPlantUiDto$.next(plantUiDto);
  }

  setInternalUserInfoDtosBasedOnPlant(infoDtos: UserInfoUiDto[]) {
    this._internalUserInfoUiDtosBasedOnPlant$.next(infoDtos);
  }

  setInternalUserInfoDtos(infoDtos: UserInfoUiDto[]) {
    this._internalUserInfoUiDtos$.next(infoDtos);
  }


  setTeamUiDtosList(teamUiDtos: TeamUiDto[]) {
    this._teamUiDtos$.next(teamUiDtos);
  }

  setUserEntityInfoDtos(userEntityInfoDtos: UserUiDto[]) {
    this._userUiDtos$.next(userEntityInfoDtos);
  }

  updateUserEntityInfoDto(userUiDto: UserUiDto) {
    let userUiDtos = this._userUiDtos$.value;
    let index = userUiDtos.findIndex(item => item.userId == userUiDto.userId);

    if (index != undefined && index > -1) {
      Object.assign(userUiDtos[index], userUiDto);
    } else {
      userUiDtos.push(userUiDto);
    }

    this._userUiDtos$.next(userUiDtos);
  }

  async loadOrganizationUiDto() {
    let userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
    try {
      let apiResponseDto = await firstValueFrom(this.fetchOrganizationUiDto(userUiDto?.orgCode!));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.updateOrganizationUiDto(apiResponseDto.data! as OrganizationWrapperUiDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOrganogramByOrgCode() {
    let userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
    if (userUiDto) {
      try {
        let apiResponseDto = await firstValueFrom(this.getOrganogramByOrgCode(userUiDto.orgCode!));
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let orgOrganogramUiDto = apiResponseDto.data as OrgOrganogramUiDto;
          this.updateOrgOrganogramUiDto(orgOrganogramUiDto);
          this.updateOrganizationUiDto(orgOrganogramUiDto.organizationWrapperUiDto!);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async loadCompanyUiDtosByOrgCode() {
    let userEntityDto = await firstValueFrom(this.store.select(selectUserUiDto));
    try {
      let apiResponseDto = await firstValueFrom(this.fetchCompanyUiDtosByOrgCode(userEntityDto?.orgCode!));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setCompanyUiDtos(apiResponseDto.data as CompanyUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadPlantUiDto(orgCode: string, companyCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPlantUiDto(orgCode, companyCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setPlantUiDtos(apiResponseDto.data as PlantUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadInternalUsersListBasedOnPlant(orgCode: string, companyCode: string, plantCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchInternalUsersBasedOnPlant(orgCode, companyCode, plantCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setInternalUserInfoDtosBasedOnPlant(apiResponseDto.data as UserInfoUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadInternalUsersList(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchInternalUsers(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setInternalUserInfoDtos(apiResponseDto.data as UserInfoUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadPlantUiDtosByOrgCode(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPlantUiDtosByOrgCode(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setPlantUiDtos(apiResponseDto.data as PlantUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOnboardingUsersList(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchOnboardingUsers(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setUserEntityInfoDtos(apiResponseDto.data as UserUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOnboardingTeamsList(orgCode: string, companyCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getTeamUiDtos(orgCode, companyCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setTeamUiDtosList(apiResponseDto.data as TeamUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private fetchOrganizationUiDto(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`organizations/${orgCode}`).pipe(
      tap(_ => console.log("Get organizations")),
      catchError(this.errorService.handleError<any>("Error while getting organizations")));
  }

  private getOrganogramByOrgCode(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`organizations/organogram/${orgCode}`).pipe(
      tap(_ => console.log("Get organogram")),
      catchError(this.errorService.handleError<any>("Error while getting organogram")));
  }

  saveOrganizationUiDto(organizationUiDto: OrganizationWrapperUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`organizations`, organizationUiDto).pipe(
      tap(_ => console.log("Save organizations")),
      catchError(this.errorService.handleError<any>("Error while saving organizations")));
  }

  private fetchCompanyUiDtosByOrgCode(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`companies/${orgCode}`).pipe(
      tap(_ => console.log("Get companies")),
      catchError(this.errorService.handleError<any>("Error while getting companies")));
  }

  saveCompanyUiDto(companyUiDto: CompanyUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`companies`, companyUiDto).pipe(
      tap(_ => console.log("Save companies")),
      catchError(this.errorService.handleError<any>("Error while saving companies")));
  }

  private fetchPlantUiDto(orgCode: string, companyCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`plants/${orgCode}/${companyCode}`).pipe(
      tap(_ => console.log("Get plants")),
      catchError(this.errorService.handleError<any>("Error while getting plants")));
  }

  private fetchInternalUsersBasedOnPlant(orgCode: string, companyCode: string, plantCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/${orgCode}/${companyCode}/${plantCode}`).pipe(
      tap(_ => console.log("Get users")),
      catchError(this.errorService.handleError<any>("Error while getting users")));
  }

  private fetchInternalUsers(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/internalUser/${orgCode}`).pipe(
      tap(_ => console.log("Get users")),
      catchError(this.errorService.handleError<any>("Error while getting users")));
  }

  private fetchPlantUiDtosByOrgCode(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`plants/${orgCode}`).pipe(
      tap(_ => console.log("Get plants")),
      catchError(this.errorService.handleError<any>("Error while getting plants")));
  }

  addCompanyandPlant(userInviteDto: UserInviteDto): Observable<any> {
    return this.http.post<any>('onboard-users-company-plants-mapping', userInviteDto).pipe(
      tap(_ => console.log("Called onboard-users-company-plants-mapping api successfully" + userInviteDto)),
      catchError(this.errorService.handleError<any>('Error while calling onboard-users-company-plants-mapping api' + userInviteDto)))
  }

  savePlantUiDto(plantUiDto: PlantUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`plants`, plantUiDto).pipe(
      tap(_ => console.log("Save plants")),
      catchError(this.errorService.handleError<any>("Error while saving plants")));
  }

  private fetchOnboardingUsers(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/${orgCode}`).pipe(
      tap(_ => console.log("Get users")),
      catchError(this.errorService.handleError<any>("Error while getting users")));
  }

  getTeamUiDtos(orgCode: string, companyCode: string) {
    return this.http.get<ServerAPIResponseDto>(`teams/${orgCode}/${companyCode}`).pipe(
      tap(_ => console.log("Get teams")),
      catchError(this.errorService.handleError<any>("Error while getting teams")));
  }
  internalUserInvitation(userInviteDto: UserInviteDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`onboard-internal-users`, userInviteDto).pipe(
      tap(_ => console.log("Invitation sent")),
      catchError(this.errorService.handleError<any>("Error while sending invitation")));
  }

  markResourceActiveOrInActive(resourceId: string, active: boolean, actionName: string) {
    let params = new HttpParams().set('resourceId', resourceId).set('active', active).set('actionName', actionName);
    return this.http.post<ServerAPIResponseDto>('markResourceActiveOrInActive', null, { params }).pipe(
      tap(_ => console.log("User active status change" + resourceId)),
      catchError(this.errorService.handleError<any>("Error while changing user active status" + resourceId)))
  }
}
